
@font-face {font-family: "Hiragino Sans GB"; src: url("assets/Hiragino-Sans-GB-W3.otf") format("opentype");}
@font-face {font-family: "League Gothic"; src: url("assets/fonts/LeagueGothic-Regular.otf") format("opentype");
                                        src: url("assets/fonts/leaguegothic-regular-webfont.woff") format("woff");
                                        src: url("assets/fonts/leaguegothic-regular-webfont.woff2") format("woff2");
}
@font-face {font-family: "Montserrat-Regular"; src: url("assets/fonts/Montserrat-Regular.otf") format("opentype")};
.App {
  text-align: center;
  margin: 0;
  min-height: 100vh;

}

.App-main {
  align-items: center;
  text-align: center;
  font-size: calc(15px + 2vmin);
  font-weight: bold;

  color: white;
}

.img-banner {
  width: 100%;
  opacity: 0.95;
}

.comingSoon {
  font-size: 15px;
  min-width: 100%;
  font-weight: bold;
}

.infoCardWrapper {
  padding: 1%;
  display: inline-block;
  width: 18%;
  min-width:197px;
}

.infoCard {
  color: white;
  text-align: center;
  display: block;
  padding-bottom: 20px;
  opacity: 0.85;
  vertical-align: top;
  display:inline-block;
  background-color: rgb(17, 17, 17);
  height: 100%;
}

.mainInfoLeft {
  color: white;
  width: 100%;
  height: 100%;
  background-color: rgb(17, 17, 17);
  opacity: 0.85;
  padding-top: 5px;
}

.mainInfoRight {
  color: white;
  width: 100%;
  height: 100%;
  opacity: 0.85;
  background-color: rgb(17, 17, 17);
  padding-top: 5px;
}

.mainInfoLeftWrapper {
  height: 420px;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  padding-top: 0px;
  width: 320px;
}

.mainInfoRightWrapper {
  height: 420px; 
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  padding-top: 0px;
  width: 320px;
}

.infoHeading {
  color: rgb(255, 102, 242);
  text-align: center;
  margin-bottom: 5px;
  margin-top: 10px;
}
